//*********************************************************************************************************************************************//
//                                                               REACT imports                                                                 //
//*********************************************************************************************************************************************//
import { useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";

//*********************************************************************************************************************************************//
//                                                                 Mui imports                                                                 //
//*********************************************************************************************************************************************//
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import ModifierIcon from "../img/ModifierIcon";
import Paper from '@mui/material/Paper';
//--------------------------------------------------------------------icons--------------------------------------------------------------------//
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//*********************************************************************************************************************************************//
//                                                         external libraries imports                                                          //
//*********************************************************************************************************************************************//
import { useForm } from "react-hook-form";

//*********************************************************************************************************************************************//
//                                                              PROVIDERS imports                                                              //
//*********************************************************************************************************************************************//
import { useUser } from "../context/UserProvider";
import { usePaths } from "../context/PathsProvider";
import { useDialog } from "../context/DialogProvider";

//*********************************************************************************************************************************************//
//                                                             COMPONENTS imports                                                              //
//*********************************************************************************************************************************************//
import { AuthorizationChecker } from "../components/AuthorizationChecker";

//*********************************************************************************************************************************************//
//                                                               DIVERS imports                                                                //
//*********************************************************************************************************************************************//
import CONFIG from "../config/default";





const Home = () => {
    const { user, selectedEstablishment, lists } = useUser();
    const { paths } = usePaths();
    const theme = useTheme();
    const navigate = useNavigate();
    const { customer_hid } = useParams();
    const sizes = {
        buttons: {
            xs: 9,
            sm: 7,
            md: 6,
            lg: 5,
            xl: 4
        },
        buttonspx: {
            xs: "450px",
            sm: "350px",
            md: "300px",
            lg: "250px",
            xl: "200px"
        }
    }


    if (!user) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <Grid container sx={{ width: "inherit" }} rowSpacing={4}>
                        <Grid xs={(12 - sizes.buttons.xs) / 2} sm={(12 - sizes.buttons.sm) / 2} md={(12 - sizes.buttons.md) / 2} lg={(12 - sizes.buttons.lg) / 2} xl={(12 - sizes.buttons.xl) / 2} />
                        <Grid xs={sizes.buttons.xs} sm={sizes.buttons.sm} md={sizes.buttons.md} lg={sizes.buttons.lg} xl={sizes.buttons.xl}>
                            <AuthorizationChecker
                                module={lists?.LICENSE_MODULE_VALUES["AEV"]}
                                baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                            >
                                <Button
                                    variant="contained"
                                    sx={{ width: "100%" }}
                                    disabled={false}
                                    onClick={() => {
                                        navigate(paths.evalList.app({ establishment_id: selectedEstablishment._id, customer_hid: customer_hid }))
                                    }}>Auto-évaluation</Button>
                            </AuthorizationChecker>
                        </Grid>
                        <Grid xs={(12 - sizes.buttons.xs) / 2} sm={(12 - sizes.buttons.sm) / 2} md={(12 - sizes.buttons.md) / 2} lg={(12 - sizes.buttons.lg) / 2} xl={(12 - sizes.buttons.xl) / 2} />
                        <Grid xs={(12 - sizes.buttons.xs) / 2} sm={(12 - sizes.buttons.sm) / 2} md={(12 - sizes.buttons.md) / 2} lg={(12 - sizes.buttons.lg) / 2} xl={(12 - sizes.buttons.xl) / 2} />
                        <Grid xs={sizes.buttons.xs} sm={sizes.buttons.sm} md={sizes.buttons.md} lg={sizes.buttons.lg} xl={sizes.buttons.xl}>
                            <AuthorizationChecker
                                module={lists?.LICENSE_MODULE_VALUES["PAQ"]}
                                baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                            >
                                <Button
                                    variant="contained"
                                    sx={{ width: "100%" }}
                                    disabled={false}
                                    onClick={() => {
                                        navigate(paths.paq.app({ establishment_id: selectedEstablishment._id, customer_hid: customer_hid }));
                                    }}
                                >Plan d'actions</Button>
                            </AuthorizationChecker>
                        </Grid>
                        <Grid xs={(12 - sizes.buttons.xs) / 2} sm={(12 - sizes.buttons.sm) / 2} md={(12 - sizes.buttons.md) / 2} lg={(12 - sizes.buttons.lg) / 2} xl={(12 - sizes.buttons.xl) / 2} />
                        <Grid xs={(12 - sizes.buttons.xs) / 2} sm={(12 - sizes.buttons.sm) / 2} md={(12 - sizes.buttons.md) / 2} lg={(12 - sizes.buttons.lg) / 2} xl={(12 - sizes.buttons.xl) / 2} />
                        <Grid xs={sizes.buttons.xs} sm={sizes.buttons.sm} md={sizes.buttons.md} lg={sizes.buttons.lg} xl={sizes.buttons.xl}>
                            {/* <AuthorizationChecker
                                module={lists?.LICENSE_MODULE_VALUES["EADM"]}
                                baseAction={lists?.BASE_ACTION_VALUES["RDO"]}
                            >
                                <Button
                                    variant="contained"
                                    sx={{ width: "100%" }}
                                    disabled={false}
                                    onClick={() => {
                                        navigate(paths.establishmentUsers.app({ establishment_id: selectedEstablishment._id, customer_hid: customer_hid }));
                                    }}
                                >Administration</Button>
                            </AuthorizationChecker> */}
                        </Grid>
                        <Grid xs={(12 - sizes.buttons.xs) / 2} sm={(12 - sizes.buttons.sm) / 2} md={(12 - sizes.buttons.md) / 2} lg={(12 - sizes.buttons.lg) / 2} xl={(12 - sizes.buttons.xl) / 2} />
                    </Grid>
                </Box>
            </>
        );
    }
};

export default Home;